

import eventsQr from './GeneralImages/eventsQR.png';


import ancaLoghin from "../utils/images/pozeTraineri/ancaLoghin.jpg";
import andreiSuciu from "../utils/images/pozeTraineri/andreiSuciu.jpg";
import ligiaUngur from "../utils/images/pozeTraineri/LigiaUngur.jpeg";
import alexandraMoldovan from "../utils/images/pozeTraineri/alexandraMoldovan.jpg";

import andreiChiran from "../utils/images/colaboratori/AndreiChiran.jpg";
import cezarCochisa from "../utils/images/colaboratori/CezarCochisa.jpg";
import costinGavaza from "../utils/images/colaboratori/CostinGavaza.jpg";
import cristiIorga from "../utils/images/colaboratori/CristiIorga.jpg";
import csabaCiugulitu from "../utils/images/colaboratori/CsabaCiugulitu.jpg";
import danaAdi from "../utils/images/colaboratori/DanaAdi.jpg";
import larisaDobrin from "../utils/images/colaboratori/LarisaDobrin.jpg";
import larisGiurgiu from "../utils/images/colaboratori/LarisaGiurgiu.jpg";
import mihaiCraciun from "../utils/images/colaboratori/MihaiCraciun.jpg";
import rares from '../utils/images/rares.jpg';
import traianMoldovanSquare from '../utils/images/pozeTraineri/TraianMoldovanSquare.jpg'

import Kaboom1 from './images/spectacoleAnterioare/Kaboom1.jpg';
import Kaboom2 from './images/spectacoleAnterioare/Kaboom2.jpg';
import Kaboom3 from './images/spectacoleAnterioare/Kaboom3.jpg';
import Kaboom4 from './images/spectacoleAnterioare/Kaboom4.jpg';
import Kaboom5 from './images/spectacoleAnterioare/Kaboom5.jpg';
import Kaboom6 from './images/spectacoleAnterioare/Kaboom6.jpg';
import Kaboom7 from './images/spectacoleAnterioare/Kaboom7.jpg';
import Kaboom8 from './images/spectacoleAnterioare/Kaboom8.jpg';
import Kaboom9 from './images/spectacoleAnterioare/Kaboom9.jpg';
import Kaboom10 from './images/spectacoleAnterioare/Kaboom10.jpg';
import Kaboom11 from './images/spectacoleAnterioare/Kaboom11.jpg';
import Kaboom12 from './images/spectacoleAnterioare/Kaboom12.jpg';
import Kaboom13 from './images/spectacoleAnterioare/Kaboom13.jpg';
import Kaboom14 from './images/spectacoleAnterioare/Kaboom14.jpg';
import Kaboom15 from './images/spectacoleAnterioare/Kaboom15.jpg';
import Kaboom16 from './images/spectacoleAnterioare/Kaboom16.jpg';
import Kaboom17 from './images/spectacoleAnterioare/Kaboom17.jpg';
import Kaboom18 from './images/spectacoleAnterioare/Kaboom18.jpg';
import Kaboom19 from './images/spectacoleAnterioare/Kaboom19.jpg';
import Kaboom20 from './images/spectacoleAnterioare/Kaboom20.jpg';
import Exploziv1 from './images/spectacoleAnterioare/Exploziv1.jpg';
import alexCioata from './images/pozeTraineri/AlexCioata.jpg'
import traianMoldovan from './images/pozeTraineri/TraianMoldovan.jpg'
import eliseWilk from './images/pozeTraineri/ELISE_WILK.jpg'
import Hunor from './images/pozeTraineri/HunorKontczey.jpg'
import Daniel from './images/pozeTraineri/DANIEL.jpg';



import Gol from './images/gol.jpg'
import NoapteaMuzeelor from './images/NoapteaMuzeelor.jpg'
import ExplozivNou from './images/ExplozivNou.jpg'

import logoKaboom2 from './images/logoKaboom2.jpg'

import afisKaboom24 from './images/afisKaboom24.jpg';
import zgomotul from './images/zgomotul.jpg';
import monoloage from './images/monoloage.jpg'

import Kaboom26 from './images/Kaboom26.jpg'
import Kaboom262 from './images/Kaboom26part2.jpg'

import Hp1 from './GeneralImages/homepage/hp1.jpg'
import Hp2 from './GeneralImages/homepage/hp2.jpg'
import Hp3 from './GeneralImages/homepage/hp3.jpg'
import Hp4 from './GeneralImages/homepage/hp4.jpg'
import Hp5 from './GeneralImages/homepage/hp5.jpg'
import Hp6 from './GeneralImages/homepage/hp6.jpg'
import Hp7 from './GeneralImages/homepage/hp7.jpg'
import Hp8 from './GeneralImages/homepage/hp8.jpg'
import Hp9 from './GeneralImages/homepage/hp9.jpg'
import Hp10 from './GeneralImages/homepage/hp10.jpg'
import Hp11 from './GeneralImages/homepage/hp11.jpg'
import Hp12 from './GeneralImages/homepage/hp12.jpg'
import Hp13 from './GeneralImages/homepage/hp13.jpg'
import Hp14 from './GeneralImages/homepage/hp14.jpg'
import Hp15 from './GeneralImages/homepage/hp15.jpg'
import Hp16 from './GeneralImages/homepage/hp16.jpg'
import Hp17 from './GeneralImages/homepage/hp17.jpg'
import Hp18 from './GeneralImages/homepage/hp18.jpg'
import Hp19 from './GeneralImages/homepage/hp19.jpg'
import Hp20 from './GeneralImages/homepage/hp20.jpg'
import Hp21 from './GeneralImages/homepage/hp21.jpg'
import Hp22 from './GeneralImages/homepage/hp22.jpg'
import Hp23 from './GeneralImages/homepage/hp23.jpg'
import Hp24 from './GeneralImages/homepage/hp24.jpg'
import Hp25 from './GeneralImages/homepage/hp25.jpg'
import Hp26 from './GeneralImages/homepage/hp26.jpg'
import Hp27 from './GeneralImages/homepage/hp27.jpg'
import Hp28 from './GeneralImages/homepage/hp28.jpg'
import Hp29 from './GeneralImages/homepage/hp29.jpg'
import Hp30 from './GeneralImages/homepage/hp30.jpg'
import Hp31 from './GeneralImages/homepage/hp31.jpg'
import Hp32 from './GeneralImages/homepage/hp32.jpg'

import Luiza from './images/luiza.png';
import Blanka from './images/Blanka.png'
import irina from './images/Irina.png'
import Ligia from './images/Ligias.png'
import Szabi from './images/Szabi.png'

import Kaboom27 from './images/Kaboom27.jpg'

import Bikeathon from './images/Bikeathon.jpg'
import Tango from './images/Tango.jpg'
import Kaboom28 from './images/Kaboom28.jpg'
import Kaboom30 from './images/Kaboom30.jpg'

/* ------------------ IMAGE CAROUSEL --------------------- */
export const imageCarousel = [
    {
        id: 1, 
        src: Hp1,
        alt: 'one',
    },
    {
        id: 2, 
        src: Hp2,
        alt: 'two',
    },
    {
        id: 3, 
        src: Hp3,
        alt: 'three',
    },
    {
        id: 4, 
        src: Hp4,
        alt: 'four',
    },,
    {
        id: 5, 
        src: Hp5,
        alt: 'five',
    },
    {
        id: 6, 
        src: Hp6,
        alt: 'six',
    },
    {
        id: 7, 
        src: Hp7,
        alt: 'seven',
    },
    {
        id: 8, 
        src: Hp8,
        alt: 'eight',
    },
    {
        id: 9, 
        src:  Hp9,
        alt: 'nine',
    },
    {
        id: 10, 
        src: Hp10,
        alt: 'ten',
    },
    {
        id: 11, 
        src: Hp11,
        alt: 'eleven',
    },
    {
      id: 12, 
      src: Hp12,
      alt: 'eleven',
    },
    {
      id: 13, 
      src: Hp13,
      alt: 'eleven',
    },
    {
      id: 14, 
      src: Hp14,
      alt: 'eleven',
    },{
      id: 15, 
      src: Hp15,
      alt: 'eleven',
    },{
      id: 16, 
      src: Hp16,
      alt: 'eleven',
    },{
      id: 17, 
      src: Hp17,
      alt: 'eleven',
    },{
      id: 18, 
      src: Hp18,
      alt: 'eleven',
    },{
      id: 19, 
      src: Hp19,
      alt: 'eleven',
    },
    {
      id: 20, 
      src: Hp20,
      alt: 'eleven',
    },
    {
      id: 21, 
      src: Hp21,
      alt: 'eleven',
    },
    {
      id: 22, 
      src: Hp22,
      alt: 'eleven',
    },
    {
      id: 23, 
      src: Hp23,
      alt: 'eleven',
    },
    {
      id: 24, 
      src: Hp24,
      alt: 'eleven',
    },
    {
      id: 25, 
      src: Hp25,
      alt: 'eleven',
    },
    {
      id: 26, 
      src: Hp26,
      alt: 'eleven',
    },
    {
      id: 27, 
      src: Hp27,
      alt: 'eleven',
    },
    {
      id: 28, 
      src: Hp28,
      alt: 'eleven',
    },
    {
      id: 29, 
      src: Hp29,
      alt: 'eleven',
    },
    {
      id: 30, 
      src: Hp30,
      alt: 'eleven',
    },
    {
      id: 31, 
      src: Hp31,
      alt: 'eleven',
    },
    {
      id: 32, 
      src: Hp32,
      alt: 'eleven',
    },
]


/* ------------------ SHOWS HOMEPAGE --------------------- */




export const homepageReviews = [
    {
        id:1,
        name: 'Ovidiu Ianculescu',
        review: 'A fost super misto la voi, le-a placut foarte mult copiilor mei',
        likes: 78,
        fav: 9,
    },
    {
        id:2,
        name: 'Laszlo Zsolt',
        review: 'Felicitari pentru ce ati realizat aseara. Again, orasul are nevoie de asta. Crestem cu totii prin evenimente de genul asta. De la copii pana la batranei. La un moment dat aveam pielea de gaina. Deci, mesajul a fost receptionat.',
        likes: 126,
        fav: 43,
    }
]

export const currentShows = [
  {
    id: 40,
    day: 'SĂMBĂTĂ',
    date: '30 NOIEMBRIE',
    year: '2024',
    title: 'IMPRO SHOW',
    subtitle: 'KABOOM UNLIMITED IMPRO 30',
    time: '19:30',
    address1: 'Bastionul Portii',
    address2: 'Cetatea Medievala',
    src: Kaboom30,
    qr: ''
  },
]


export const spectacoleViitoare = [
  {
    id: 40,
    src: Kaboom30,
    title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 30 ',
    hour: '19:30',
    date: '30',
    month: 'NOIEMBRIE',
    address: 'Bastionul Cetatii, Cetatea medievala',
    price: 'Suport Artisti: 40RON adulti, 30RON copii',
    description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
  },
]

export const spectacoleAnterioare = [
    {
      id: 1,
      src: Kaboom1,
      title: 'KaBoom Unlimited IMPRO 1 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '16',
      month: 'JUN',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },
    {
      id: 2,
      src: Kaboom2,
      title: 'KaBoom Unlimited IMPRO 2 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '28',
      month: 'JUL',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 3,
      src: Kaboom3,
      title: 'KaBoom Unlimited IMPRO 3 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '25',
      month: 'AUG',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 4,
      src: Kaboom4,
      title: 'KaBoom Unlimited IMPRO 4 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '22',
      month: 'SEP',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 5,
      src: Kaboom5,
      title: 'KaBoom Unlimited IMPRO 5 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '28',
      month: 'OCT',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 6,
      src: Kaboom6,
      title: 'KaBoom Unlimited IMPRO 6 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '17',
      month: 'NOV',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 7,
      src: Kaboom7,
      title: 'KaBoom Unlimited IMPRO 7 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '8',
      month: 'DEC',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 8,
      src: Kaboom8,
      title: 'KaBoom Unlimited IMPRO 8 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '19',
      month: 'JAN',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 9,
      src: Kaboom9,
      title: 'KaBoom Unlimited IMPRO 9 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '28',
      month: 'FEB',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 10,
      src: Kaboom10,
      title: 'KaBoom Unlimited IMPRO 10 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '20:00',
      date: '24',
      month: 'MAR',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 11,
      src: Kaboom11,
      title: 'KaBoom Unlimited IMPRO 11 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '23',
      month: 'APR',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 12,
      src: Kaboom12,
      title: 'KaBoom Unlimited IMPRO 12 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '25',
      month: 'MAI',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 13,
      src: Kaboom13,
      title: 'KaBoom Unlimited IMPRO 13 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '25',
      month: 'JUN',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 14,
      src: Kaboom14,
      title: 'KaBoom Unlimited IMPRO 14 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '20',
      month: 'JUL',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 15,
      src: Kaboom15,
      title: 'KaBoom Unlimited IMPRO 15 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '24',
      month: 'AUG',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 16,
      src: Kaboom16,
      title: 'KaBoom Unlimited IMPRO 16 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '21',
      month: 'SEP',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 17,
      src: Kaboom17,
      title: 'KaBoom Unlimited IMPRO 17 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '20',
      month: 'OCT',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 18,
      src: Kaboom18,
      title: 'KaBoom Unlimited IMPRO 18 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '17',
      month: 'NOV',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },{
      id: 19,
      src: Kaboom19,
      title: 'KaBoom Unlimited IMPRO 19 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '15',
      month: 'DEC',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },
    ,{
      id: 20,
      src: Kaboom20,
      title: 'KaBoom Unlimited IMPRO 20 ',
      address: 'Turnul portii, Cetatea Medievala',
      hour: '19:30',
      date: '26',
      month: 'JAN',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
    },
    {
      id: 21,
      src: Exploziv1,
      title: `"Teatru Tanar - "Exploziv de Elise Wilk"`,     
      address: 'Turnul portii, Cetatea Medievala',
      hour: '18:00',
      date: '4',
      month: 'FEB',
    },
    ,{
      id: 29,
      src: ExplozivNou,
      title: 'Teatru Tanar - "Exploziv" de Elise Wilk',
      address: 'Turnul Portii - Cetatea Medievala',
      hour: '19:00',
      date: '19',
      month: 'MAI',
      price: 'Suport Artisti: Adulti 50RON & Elevi 30RON',
    },{
      id: 28,
      src: NoapteaMuzeelor,
      title: 'Ateliere, EXPO, jamming with the DJ - NOAPTEA MUZEELOR',
      address: 'Turnul Portii - Cetatea Medievala',
      hour: '16:00 - 02:00',
      date: '18',
      month: 'MAI',
      price: '',
    },{
      id: 27,
      src: Gol,
      title: 'One man show -  "GOL" cu Serban Borda',
      address: 'Turnul Portii - Cetatea Medievala',
      hour: '19:30',
      date: '9',
      month: 'MAI',
      price: 'Suport Artisti: 30RON',
    },
    {
      id: 30,
      src: afisKaboom24,
      title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 24 ',
      address: 'Turnul Portii - Cetatea Medievala',
      hour: '20:00',
      date: '30',
      month: 'MAI',
      price: 'Suport Artisti: 30RON',
      description: 'Spectacolul de improvizație KaBoom Unlimited Impro, adună 4 improvizatori și un pianist - colaboratori ai INCUBATOR 13. Un gen de show mereu deschis, liber, care se desfășoară cu ajutorul publicului și care la fiecare reprezentație este nou și plin de umor.',
  },
  {
    id: 31, 
    src: ExplozivNou,
    title: `"Teatru Tanar - "Exploziv de Elise Wilk" - Ultima reprezentatie`,
    address: 'Turnul Portii - Cetatea Medievala',
    hour: '19:00',
    date: '2',
    month: 'IUNIE',
    price: 'Suport Artisti: 15RON elevi & 30RON adulti',
    description: 'Exploziv – un spectacol cu și despre liceeni. Este un spectacol despre relația dintre ei, părinți și profesori, despre comunicare (sau mai degrabă lipsa ei) și despre dinamica acestor grupuri',  
    description1:'"Exploziv" este un spectacol care ar trebui văzut de toate aceste categorii și nu numai.Super bonus: veți descoperi niște tineri absolut minunați!!!',
    description2: '',
  },
  {
    id: 32,
    src: monoloage,
    title: 'Monoloagele vaginului 16+ - spectacol invitat Sibiu',
    hour: '20:00',
    date: '13',
    month: 'IUNIE',
    price: 'Suport Artisti: 40RON - spectacol 16+',
    description: '"Monoloagele vaginului", dupa Eve Ensler - UN SPECTACOL INEDIT',
    description1:'Vă propunem un spectacol simplu, curat, al cărui ingredient principal este lejeritatea și finețea actoricească a celor trei protagonisteȘ Serenela Mureșan, Cristina Ragos și Codruța Vasiu. <br /> "Monoloagele vaginului" - un exercițiu actoricesc de o rară sensibilitate. O porție de umor de cea mai bună calitate. Emoție, senzualitate, feminitate.',
    description2: 'Nu vă lăsați induși în eroare de titlu. Nimic nu va fi vulgar sau la prima mână',
  },
  {
    id: 33, 
    src: logoKaboom2,
    title: 'IMPRO SHOW - KABOOM UNLIMITED IMPRO 25 ',
    hour: '20:00',
    date: '25',
    month: 'IUNIE',
    price: 'Suport Artisti: 30RON copii & 40RON adulti',
    description: '',
    description1:'',
    description2: '',
  },
  {
    id: 34,
    src: zgomotul,
    title: 'ZGOMOTUL și FURIA  - Oigăn și Iordache',
    hour: '20:00',
    date: '28',
    month: 'IUNIE',
    price: 'Suport Artisti: 40RON',
    description: 'Zgomotul și furia este un proiect experimental demarat de Iordache și Oigăn în 2015 și care a trecut prin câteva reîncarnări foarte diferite în București, la Cluj și pe litoral.',
    description1:'Ceea ce s-a păstrat a fost latura avangardistă și relativ bizară.',
    description2: 'Zgomotul și furia a renăscut ca acțiune muzical-performativă anul acesta, prin două concerte la Zadar Bar - loc esențial al avangardei muzicale bucureștene - și la Gourmet Pub, o adevărată oază a muzicii creative din Galați. Acum suntem si in Tg Mures la INCUBATOR13',
  
  }
  
    
  ]
  

  export const team = [
    {
      id: 1,
      name: "Rareș Budileanu",
      img: rares,
      description: "actor, regizor, profesor, trainer, membru fondator",
      cv: true,
      fb: 'https://www.facebook.com/rares.budileanu',
      insta: 'https://www.instagram.com/raresbudileanu/'
    },
    {
      id: 2,
      name: "Anca Loghin",
      img: ancaLoghin,
      description: "actriță, coordonator proiecte, membru fondator, trainer",
      cv: true,
      fb: 'https://www.facebook.com/anca.loghin.1',
      insta: ''
    },
    {
      id: 3,
      name: "Ligia Ungur",
      img: ligiaUngur,
      description: `
      La pas după cuvintele & imaginile care pot lăsa urme perceptibile în lumile digitale și în afara lor.
      Un om curios de creativitate în industria digitală. Își numără zilele prin branding creativ, editare
      video, project management, social media management, fotografie de stradă."`,
      cv: true,
      fb: 'https://www.facebook.com/ligia.ungur',
      insta: 'https://www.instagram.com/ligia.ungur/'
    },
    {
      id: 4,
      img: andreiSuciu,
      name: "Suciu Andrei-Aurel",
      description: `graphic designer, Oroboro Studio`,
      cv: true,
      fb: 'https://www.facebook.com/oroboro.idanim',
      insta: 'https://www.instagram.com/oroboro_studio/'
    },
  ];
  
  export const colaboratori = [
    {
      id: 1,
      name: "Mihai Crăciun",
      img: mihaiCraciun,
      description: "actor",
      cv: false,
      fb: 'https://www.facebook.com/craciun.mihai.921',
      insta: 'https://www.instagram.com/mihai_m_craciun_/'
    },
    {
      id: 2,
      name: "Costin Gavază",
      img: costinGavaza,
      description: "actor",
      cv: false,
      fb: 'https://www.facebook.com/costin.gavaza',
      insta: 'https://www.instagram.com/costingavaza/'
    },
    {
      id: 3,
      name: "Larisa Dobrin",
      img: larisaDobrin,
      description: "actriță",
      cv: false,
      fb: 'https://www.facebook.com/dobrin.larisa.9',
      insta: 'https://www.instagram.com/larisaa3/'
    },
    {
      id: 4,
      name: "Alexandra Moldovan",
      img: alexandraMoldovan,
      description: "actriță",
      cv: false,
      fb: 'https://www.facebook.com/alexandra.moldovan.589100',
      insta: 'https://www.instagram.com/alemoldovan1/'
    },
    {
      id: 5,
      name: "Csaba Ciugulitu",
      img: csabaCiugulitu,
      description: "actor",
      cv: false,
      fb: 'https://www.facebook.com/ciugulitu.csaba',
      insta: 'https://www.instagram.com/csaba_ciugulitu/'
    },
    {
      id: 6,
      name: "Andrei Chiran",
      img: andreiChiran,
      description: "actor",
      cv: false,
      fv: 'https://www.facebook.com/mafia.andrei',
      insta: 'https://www.instagram.com/monoinstadrama/'
    },
    {
      id: 7,
      name: "Cristi Iorga",
      img: cristiIorga,
      description: "actor",
      cv: false,
      fb: 'https://www.facebook.com/cristianiorga',
      insta: 'https://www.instagram.com/cristi.iorga/'
    },
    {
      id: 8,
      name: "Larisa Giurgiu",
      img: larisGiurgiu,
      description: "actriță",
      cv: false,
      fb: 'https://www.facebook.com/larisa.giurgiu.77',
      insta: 'https://www.instagram.com/larisagiurgiu/'
    },
    {
      id: 9,
      name: "Dana Mihaela Adi",
      img: danaAdi,
      description: "actriță",
      cv: false,
      fb: 'https://www.facebook.com/mia.adi',
      insta: 'https://www.instagram.com/adi.d.mihaela/'
    },
    {
      id: 10,
      name: "Cezar Cochisă",
      img: cezarCochisa,
      description: "pianist",
      cv: false,
      fb: 'https://www.facebook.com/cezar.cochisa',
      insta: 'https://www.instagram.com/cezarcochisa/'
    },
    {
      id: 11,
      name: " Daniel Csikos",
      img: Daniel,
      description: "pianist",
      cv: false,
      fb: 'https://www.facebook.com/daniel.csikos.35',
      insta: 'https://www.instagram.com/danielcsikos43/'
    },
  ];
  


  export const descrierePersonala = [
    {
      id: 1,
      name: "Suciu Andrei-Aurel",
      description:
        'Suciu Andrei-Aurel s-a născut pe 02 Octombrie 1996. A absolvit Colegiul Agricol Traian Săvulescu din Târgu-Mureș în 2015. Din anul 2015 a inițiat studiul în Graphic Design, ajutat de firma Reea și ulterior dobândind cunoștințe și competențe în mod independent și autodidact. În 2016 a avut oportunitatea de a aplica cunoștințele acumulate, realizând prima grafică de afiș pentru producția teatrală intitulată "Să zicem da", un spectacol de improvizație interpretat de un grup de tineri liceeni talentați la Teatrul Național Târgu Mureș, coordonați de actorul Rareș Budileanu. Între timp, în 2016 s-a angajat în calitate de graphic designer la Brandul Armura, ramură a Sportlife România, unde activează și în prezent, contribuind la crearea de grafică digitală pentru echipament sportiv. Până în prezent, Andrei a fost implicat în diverse domenii artistice, inclusiv în artă, unde a început să dezvolte propriul stil artistic, în designul grafic și în muzică, având competențe în mai multe instrumente muzicale. În 2021 a reluat colaborarea cu actorul Rareș Budileanu într-un proiect important. Aceștia au pus bazele unei asociații culturale denumite "Incubator 13", pe care Rareș o planifica de mult timp. Împreună, au creat logo-ul pentru Incubator 13, iar ulterior, Andrei s-a alăturat asociației ca membru și graphic designer, responsabil de realizarea designului grafic pentru afișe, postări pe social media, stickere, bannere, tricouri și alte materiale de promovare. În anul 2022, a fondat Oroboro Studio SRL, o companie specializată în servicii de graphic design, oferind o gamă variată de servicii de design grafic și ilustrații.',
      src: andreiSuciu,
    },
    {
      id: 2,
      name: "Anca Loghin",
      description: `Anca Loghin s-a născut pe 6.11.1977 la Târgu Mureș. În 2003 a absolvit Universitatea de Artă 
          Teatrală Tg. Mureș specializarea actorie. Din 2018 este student doctorand la aceeași universitate. 
          A fost angajată ca actriță la Teatrul Național Tg. Mureș (2003-2006) apoi la Teatrul Andrei 
          Mureșanu Sfîntu Gheorghe (2006-2008) și din nou la Teatrul Național Tg. Mureș (2008-2021) 
          unde a interpretat peste 30 de roluri în producțiile teatrelor amintite. 
          Activitatea ei artistică s-a extins și în alte teatre din țară, cum ar fi : Teatru 74, Teatrul Municipal 
          Târgovişte , Teatrul Nottara Bucureşti, Teatrul Luni de la Green Hours, Teatrul de Comedie 
          Bucureşti, Teatrul Ariel Tg. Mureş.
          Zonele ei de căutare artistică au fost exprimate și în producții independente în spectacole ca : 
          Mă-nsor la Padova scenariu original propriu – 2004, Hazard de Victor Haim regia Mihai Ardelean, 2006, Ouibada coregrafia Gigi Căciuleanu, Teatrul de Comedie 2007, La Picolla Banda regia 
          Rareș Budileanu- muzical limba germană Șc. F. Schiller/Lic. Vocațional de Artă 2016 ( scenografie ) și altele. 
          În 2017 a fost selectată în programul “10 pentru film” – în cadrul festivalului de film TIFF.
          Experiența ei artistică a fost îmbunătățită prin participarea la ateliere conduse de artiști naționali 
          și internaționali renumiți : În căutarea teatrului existențial condus de David Esrig 2007/2008, 
          Statut și relație scenică în spațiul mic condus de Vlad Massaci 2010, Exerciții Shakespeare 
          condus de Yuri Kordonski 2015, iar în producții cinematografice are următoarele apariții : Sex 
          Traffic regia David Yates 2004, Billion Star Hotel regia Alecs Năstoiu 2016, The Secret of 
          Pin-Up Island regia Alecs Năstoiu 2017, etc.
           Este membru fondator al Asociației INCUBATOR13.`,
      src: ancaLoghin,
    },
    {
      id: 3,
      name: "Rareș Budileanu",
      description: `Rareș Budileanu s-a născut pe 14.09.1975 la Sibiu, unde a absolvit Liceul de Artă/ secția muzică, 
          în anul 1994. În 1999 a absolvit Universitate de Artă Teatrală Tg. Mureș/ secția actorie, iar în anul 
          2010 a terminat studiile de Master - Regia spectacolului contemporan, tot la Universitatea de Artă 
          Tg. Mureș. Din 2018 este student doctorand la Universitatea de Artă Tg. Mureș.
          Este angajat ca actor la Teatrul Național Tg. Mureș din 1999, unde a jucat peste 40 de roluri în 
          producțiile teatrului. Tot aici a regizat câteva spectacole de teatru la ambele secții -română și 
          maghiară. Activitatea sa artistică s-a desfășurat și în alte teatre din țară precum: Teatrul de Comedie(București), Teatrul Tony Bulandra(Târgoviște), Teatrul Andrei Mureșanu(Sfântu Gheorghe), 
          Teatrul Nottara(București).
          Pe zona independentă a jucat sau regizat în multe spectacole, cea mai notabilă colaborare fiind 
          cea cu Ada Milea, participând la numeroase festivaluri atât în țară cât și în străinătate.
          În calitate de trainer/coordonator de ateliere cu copii și adolescenți, are o experiență de peste 15 
          ani, ținând ateliere de actorie, mișcare, improvizație în diferite programe : MIRROR – atelier de 
          mişcare – școala de vară Grenoble 2005, Atelier de mişcare şi improvizaţie – Brasov 2011, Atelier de improvizație, mișcare, actorie 2014/2015 Teatrul Național Tg. Mureș, ZBOR – atelier mișcare și improvizație – Bistrița 2017, What’s IMPRO - Universitatea de Arte din Târgu-Mureș Tg. 
          Mureș 2019. A fost profesor de actorie la Liceul Vocațional de Artă Tg. Mureș în perioada 
          2018-2020.
           Ca regizor, a coordonat peste 15 spectacole de diferite genuri în Tg. Mureș, Oradea, Reșița, aici 
          putem aminti câteva titluri : Pisica verde de Elise Wilk – Studio 2.1 –UAT Tg Mureş 2012, 
          Camera 701 de Elise Wilk- T.N. Tg. Mureş 2013, Să zicem DA – spectacol de improvizație(trupa 
          de liceeni Guga Jr.) 2016 Teatrul Național Tg. Mureș, La Picolla Banda- muzical limba germană 
          Șc. F. Schiller/Lic. Vocațional de Artă 2016, A kripli –de Martin McDonagh -T.N. Tg Mureș – 
          secția maghiară 2017, Săraci de Lucy Thurber- coproducție Incubator13/T.N.Tg.Mureș 2019, 
          Exploziv de Elise Wilk – Teatrul Arcadia – Oradea 2019, Niște zile ciudate de Elise Wilk – 
          Teatrul Regina Maria Oradea 2021.
          Este membru fondator al Asociației INCUBATOR13.`,
      src: rares,
    },
    {
      id: 4,
      name: "Ligia Ungur",
      description: `Mirela-Ligia Ungur s-a născut în 1998 la Târgu-Mureș. A urmat cursurile Facultății de Litere din 
          cadrul Universității Babeș-Bolyai din Cluj-Napoca, specializarea Norvegiană-Literatură
          Comparată. În 2020 și-a susținut lucrarea de licență în domeniul Literatură și Film, căutând chei 
          de interpretare a cinema-ului contemporan prin intermediul literaturii lui Franz Kafka. 
          A lucrat în colaborări artistice încă din perioada liceului, când a făcut parte din trupa de juniori a 
          Teatrului Național din Târgu-Mureș, compania Liviu Rebreanu. În cadrul acestei trupe, în perioada 
          2015-2017, a făcut parte din patru producții teatrale: “SHOES” (r. Scott Johnston), “WANT” (r. 
          Scott Johnston), spectacolul de improvizație “Să Zicem DA” (coordonator: Rareș Budileanu), 
          “Love Is…” (de Andreea Radu, r. Roxana Marian) - pentru care a colaborat atât pe partea de 
          actorie cât și scenariu. În aceeași perioadă a participat la atelierul de scenografie coordonat de 
          Măriuca Ignat. În cadrul atelierului de scriere dramatică organizat de Andreea Radu și Olga 
          Macrinici, a început scrierea piesei “La Est de Par(ad)is sau Cert E că Sunt Alergic la Mere”, text 
          care i-a adus recunoașterea dramaturgului Matei Vișniec. O altă colaborare notabilă pe care a 
          avut-o în această perioadă a fost proiectul de teatru/ documentar “Les Enfants du Monde” al 
          autorului francez Fabrice Melquiot. 
          În perioada facultății a participat activ la clubul de film “Palombella Rossa” din cadrul Facultății de 
          Litere, și a susținut seminare pe teme de literatură și film, ajungând astfel să aprofundeze 
          concepte esențiale și să dezvolte o gândire critică în raport cu ceea ce înseamnă cultură vizuală. 
          În 2018 a avut prima experiență practică în industria filmului, ca voluntară la TIFF (Festivalul 
          Internațional de Film Transilvania), pe partea de promovare a filmelor și evenimentelor. 
          În 2020 a fost selectată la atelierul de film Work in Progress, organizat de 3g HUB Târgu-Mureș, 
          unde a învățat bazele producției de film: înregistrare de sunet, înregistrare video, conceperea și 
          dezvoltarea scenariului, actorie de film și editare video. La finalul atelierului a produs scurtmetrajul 
          “Word for Word”, pentru care a semnat scenariul, regia și montajul și care a intrat în competiția 
          festivalului 3g Short Film Fest. Scurtmetrajul “Mind Does When One Doesn’t”, pe care l-a produs 
          în 2021 și pentru care a făcut scenariul, regia și montajul video, i-a adus participarea la festivalul 
          internațional Vertifilms din Praga, în luna noiembrie a aceluiași an. Tehnicile de editare video și de 
          scriere creativă pe care le-a învățat lucrând în domeniul filmului i-au adus ulterior colaborări în 
          zona de social media: în 2022 a creat tutoriale video pentru YouTube, iar începând cu anul 2022 a 
          avut multiple colaborări pentru Instagram cu localuri și producători locali din Târgu-Mureș. 
          În prezent e responsabilă de crearea de conținut video, de promovare, copywriting și branding 
          pentru rețelele sociale ale asociației Incubator 13, din a cărei echipă face parte începând cu anul 
          2022.`,
      src: ligiaUngur,
    },
    {
      id: 5,
      name: "Alex Cioata",
      description: `Alex-Lucian Cioată (n. 1997) a susținut
      numeroase concerte de muzică clasică,
      cântând la vioară ca membru al Filarmonicii
      de Stat din Târgu-Mureș, concerte naționale
      și internaționale ca violonist în cvartetul
      Chordis, este chitarist în numeroase proiecte
      muzicale, dar totodată a compus muzică și a
      înregistrat în studio alături de producători care
      au lucrat pentru artiști de nivel mondial.
      Studiile de licență pe interpretare muzicală
      le-a absolvit la Academia Națională de
      Muzică “Gheorghe Dima” din Cluj-Napoca, unde a învățat de la artiști recunoscuți pe
      plan internațional. În prezent este violonist în Filarmonicii de Stat din Târgu-Mureș și predă
      la rândul său cursuri de vioară, muzică de cameră și orchestră la Liceul Vocațional de
      Artă din Târgu-Mureș, lucrând cu elevi de toate vârstele, de la clasele primare, până la
      ultimele de liceu. Totodată, predă chitară acustică în cadrul unei școli private de muzică
      din Sighișoara.
      Genurile muzicale nu au fost niciodată o barieră, astfel a îmbinat cu succes pasiunile și
      căutările stilistice cu muzica clasică. Încă din perioada liceului a organizat un concert
      rock-simfonic, transcriind pentru trupă rock și orchestră simfonică piese celebre aranjate în
      acest fel, iar unde a fost nevoie, a compus aranjamentele pentru orchestră. Scrierea de
      partituri nu s-a oprit, iar de peste 4 ani colaborează cu una dintre cele mai mari case de
      discuri din România.`,
      src: alexCioata,
    },
    {
      id: 6,
      name: "Lector univ. dr. Traian Moldovan",
      description: `Născut în anul 1969, este licențiat în domeniul Psihologie și Pedagogie, doctor în Psihologie, cu masterat în Psihologie clinică și tehnici de consiliere și psihoterapie. Din anul 2000 este cadru didactic universitar, titular în prezent la Universitatea de Arte din Targu Mureș și cadru didactic asociat la Universitățile de Medicină, Farmacie, Științe și Tehnologie ”George Emil Palade” și ”Dimitrie Cantemir” din același oraș. Este membru în Asociația Psihologilor din România din anul 2002, membru al Colegiului Psihologilor din România (principal în domeniul Psihopedagogie specială, specialist în domeniu Consiliere educațională, supervizor și membru în comitetul director al filialei COPSI din județul Mureș). Este președintele Asociației Științifice ”Equilibrium”, asociație acreditată COPSI. De asemenea, este membru al Asociației Psihologilor Americani și membru în Rețeaua privată a evaluatorilor fondurilor structurale din cadrul Ministerului de Finanțe din România. În cadrul Universității de Arte din Târgu Mureș este director al Centrului de Consiliere și Orientare în Carieră, membru în Senatul UAT și membru al Comisiei de Asigurare a Calității în Învățământ.
      A publicat șase cărți în calitate de autor principal și coautor, peste 10 cursuri universitare, 41 de articole științifice, din care 11 articole cotate ISI și este membru în numeroase comitete științifice din cadrul unor conferințe internaționale. A participat în echipa de implementare a 4 proiecte CNFIS-FDI câștigate de Universitatea de Arte din Târgu Mureș, în două dintre ele având funcția de manager proiect. A participat în calitate de Expert pe probleme de consiliere și orientare în carieră în trei proiecte POSDRU.  
      Creația artistică se rezumă la 5 expoziții personale și peste 20 de expoziții colective în domeniul Fotografie. Este membru al Fotoclubului Marx Jozsef din Tîrgu Mureș.`,
      src: traianMoldovan,
    },
    {
      id: 7,
      name: "Elise Wilk",
      description: `Elise Wilk (n. 29 iulie 1981, Braşov), s-a numărat în 2008 printre câştigătorii concursului dramAcum5 cu primul ei text de teatru, „S-a întâmplat într-o joi”. De atunci, textele ei sunt montate în teatre din România şi din străinătate, fiind traduse până acum în 12 limbi.
      A câștigat Premiul Ambasadei Irlandei pentru cel mai bun text montat în stagiunea 2012-2013 (Pisica verde), Concursul Naţional de Dramaturgie organizat de Teatrul Național Timișoara (2015, Avioane de hârtie) și Concursul de dramaturgie-monodramă organizat de Teatrul George Bacovia din Bacău (2017, Crocodil). O parte din textele ei au fost adaptate pentru radio. În 2017 și 2018, spectacolul cu piesa Exploziv, o producție a Teatrului Național Radiofonic, a fost marele câștigător la competiții de pe trei continente, obținând Marele Premiu Asia-Pacific Broadcasting Union 2017, Premiul Grand Prix Marulic 2018 și Medalia de Aur la New York Festivals 2018.
      Revista Forbes Romania a numit-o printre tinerii trendsetteri ai anului 2014, iar Decât o Revistă printre cei 100 de oameni pentru România de mâine (2018).A participat în programe internaționale ca Forumul Tinerilor Autori Europeni din cadrul Bienalei de Teatru de la Wiesbaden, Germania (2014), Hot Ink la Teatrul Lark din New York (2015), Fabulamundi. Playwriting Europe (2013-2020) și GalataPerform Istanbul (2021).
      În 2019 primește distincția Personalitatea anului în diaspora germană din partea Internationale Medienhilfe. În anul 2021, textul Crocodil, tradus în italiană de Roberto Merlo, câștigă premiul internațional Carlo Annoni la Milano. În 2022, piesa Dispariții, în traducerea Joannei Kornas-Warwas, câștigă premiul pentru dramaturgie est-europeană AURORA în Polonia. În același an, Elise Wilk primește Premiul pentru dramaturgie contemporană din partea Teatrului Dramaturgilor Români. 
      Este doctor în teatru și artele spectacolului (Universitatea de Arte din Târgu Mureș, 2020), cu o teză despre teatrul pentru publicul tânăr în România, iar din 2021 predă scrierea dramatică la aceeași universitate.
      Piesele sale sunt traduse în : germană, maghiară, bulgară, greacă, italiană, polonă, finlandeză, cehă, franceză,engleză și jucate în teatre din toată Europa : Teatro Stabile Genova, regia Fiorenza Pieri, 2018, Teatrul  Leti Praga, regia: Kasha Jandáčková,  noiembrie 2018 (spectacol lectură), Theatre de la Huchette Paris, regia Laurent Suire, iunie 2019 (spectacol lectură), Theatre de la Veranda, Paris, regia Lisa Wurmser, premiera iulie 2022 în cadrul Avignon Off, Omada Parastatikon Technon proTASI Atena, regia Dimitrios Finitsis, data premierei: septembrie 2022, Deutschlandfunk Kultur, Germania, regia Cordula Dickmais, spectacol radiofonic, premiera 13 octombrie 2021, READ Festival Helsinki, Finlanda, regia David Kozma, octombrie 2022 (spectacol-lectură).`,
      src: eliseWilk,
    },
    {
      id: 8,
      name: "Alexandra Moldovan",
      description: `Alexandra Moldovan s-a născut în 1996 în Târgu-Mureș.  A urmat studiile de licență și master ale Facultății de Teatru din cadrul Universității de Arte din Târgu-Mureș, studii pe care le încheie în 2019. Din 2020 până în prezent coordonează grupe de teatru pentru copii și adolescenți, din 2021 face parte din mișcarea independentă  „ Cactus ”.
      Din proiectele artistice amintim:  (2016) Eu & tu->Ei sau Ne-am luat televizor regia: Radu Andreea ArtFusion Transylvania, Cluj Napoca, ( 2015 )Regele Lear, regia: Gavriil Pinte, Teatrul Regina Maria, Oradea, ( 2017 ) Cartea junglei, regia: Oana Leahu, Teatrul Național Liviu Rebreanu, Târgu-Mureș, ( 2018 ) Săraci, regia: Rareș Budileanu, Teatrul Național Liviu Rebreanu, Târgu-Mureș,  ( 2017 ) Coregraf în spectacolul Offline, regia: Laurențiu Blaga, Teatrul Național Liviu Rebreanu, Târgu-Mureș, ( 2017 ) Performance stradal, Reghin și spectacole stradale cu trupa Reghinactorii.
      A urmat cursuri de dans sportiv timp de zece ani și a participat la numerose workshop-uri și rezidențe din care amintim: workshop improvizație cu Scott Johnsson, Rareș Budileanu, workshop contact improvisation cu Andreea Belu, workshop suzuki cu Ioan Ardelean,workshop film Claudiu Trandafir, Vlad Rădescu, workshop de marionete Beatrice Cozmolici, workshop machiaj Ivona Velicu, workshop teatru Cristian Theodor Popescu.`,
      src: alexandraMoldovan,
    },
    {
      id: "9",
      name: "Kontczey Hunor",
      description: `Kontczey Hunor s-a născut pe 20 martie 2001. Absolvent al Liceului Teoretic „Bolyai Farkas” pe profil
      de matematică-informatică, s-a decis să își caute în continuare o carieră cu mai multă libertate, decât 
      statul în birou. A ales Multimedia, axat pe partea de video a acestuia, și din 2021 până-n prezent este 
      student la Universitatea de Arte din Târgu-Mureș, unde studiază Comunicare Audio-Vizuală, 
      Scenaristică și Publicitate. Fiind în anul 3 de studii, urmează examenul de licență care se va baza pe un 
      proiect numit Teatrul Popular, în cadrul Teatrului Național din Târgu-Mureș, în colaborare cu 
      Universitatea de arte, unde el a fost implicat ca și cameraman, editor, pentru documentarea acestui 
      proiect care a implicat tot orașul. 
      În colaborare cu Teatrul Național din Târgu-Mureș, a realizat documentarea a 3 proiecte culturale, cum 
      ar fi teatrul popular, sau alte proiecte implicând elevi din Mureș în viața culturală și teatrală. 
      Pe lângă proiecte culturale și universitate, Hunor este activ ca și cameraman în domeniul 
      evenimentelor, făcând parte de o echipă de evenimente, care oferă servicii complete pentru nunți, unde 
      are rolul de cameraman și editor la nunți, botezuri, petreceri.
      Este activ în domeniul de reclame, on-line marketing, lucrând împreună cu mai multe firme Mureșene 
      pentru a realiza filmări de reclamă sau conținut marketing, respectiv: Country Tg-Mures, Greenbox 
      Delivery, Solar Sun System, Bearking, Sushi Master.
      Nișa preferată lui de filmmaking este cea de comerciale cinematice, dar și aftermovie de partyuri, și 
      recent își încearcă talentul și în domeniul de clipuri muzicale.
      Visul lui este de a crea un studio de Podcast, pentru uz propriu dar și pentru închiriere/prelucrare 
      podcasturi pentru oamenii din Târgu-Mureș care vor să modernizeze lumea Multimedia, Marketing și 
      să aducă trendurile dinafara țării la noi.`,
      src: Hunor,
    },
  ];
  

  export const atelierDetalii = [
    {
      id: 1,
      img: rares,
      title: "Rareş Budileanu – Statut, relaţie şi comunicare în spaţiul mic",
      name: "Rareș Budileanu",
      descriere:
        "Prin acest atelier propun o apropiere de sine, conştientizarea corpului, a gestului, a privirii şi chiar a cuvântului. Atelierul  va fi structurat în două părţi: prima parte va consta într-o încălzire corporală axată pe segmentele corpului şi conştientizarea acestora; a doua parte va conţine exerciţii de mişcare, relaţie improvizaţie şi comunicare nonverbală şi verbală.",
    },
    {
      id: 2,
      img: ancaLoghin,
      title: "Anca Loghin - Text și subtext",
      name: "Anca Loghin",
      descriere:
        "Atelier care are ca obiectiv o mai bună înțelegere a textelor prin intermediul citirii lor din perspectiva artistică. Ce se schimbă când trecem de la lectură la joc?  Cum apare emoția atunci când începem să ne jucăm personajul? Cum ajungem de la emoție la empatie? Cum ne ajută empatia la o mai bună înțelegere și memorare? Vom încerca să răspundem impreună la aceste întrebari (și nu numai) citind, analizând și interpretând atât fragmente din texte clasice clasice (“ Ion” de Liviu Rebreanu, “Enigma Otiliei” de George Calinescu și “Maitreyi” de Mircea Eliade), cât și din dramaturgia contemporană romanească(“Pisica verde” și Exploziv” ambele de Elise Wilk).",
    },
    {
      id: 3,
      img: alexCioata,
      title: "Alex Cioată - Sunetul  e în mine",
      name: "Alex Cioata",
      descriere:
        "Atelier interactiv de muzică pentru adolescenți. Participanții vor învăța bazele muzicii într-un mod nonformal, folosindu-se, în primul rând, de corpul lor, de diferite obiecte din mediul înconjurător și bineînțeles mișcându-se. Vom încerca o întoarcere în timp, accesând bazele primare de expresie și expresivitate vocală și fizică.",
    },
    {
      id: 4,
      img: traianMoldovanSquare,
      title: "Traian Moldovan – Activități de cunoaștere și dezvoltare personală",
      name: "Lector univ. dr. Traian Moldovan",
      descriere:
        "Activitățile de cunoaștere și dezvoltare personală sunt deosebit de importante pentru adolescenți, deoarece aceștia se află într-un moment crucial al vieții lor, în care își formează identitatea și se pregătesc pentru tranziția spre viața adultă. Interacțiunile sociale mediate de psiholog prin dezbateri deschise vor încuraja adolescenții să-și exprime opiniile și să participe activ la procesul creativ/decizional. De asemenea, vor fi sprijiniți să-și dezvolte abilitățile de comunicare și argumentare, să-și creeze o perspectivă mai largă asupra problemelor și să-și îmbunătățească abilitățile de luare a deciziilor.",
    },
    {
      id: 5,
      img: ligiaUngur,
      title: "Ligia Ungur – Branding creativ pe retelele sociale ",
      name: "Ligia Ungur",
      descriere:
        "Imaginile ne influențează modul în care gândim, ne imaginăm și percepem lumea. În 2023, cultura ne este transmisă într-o mare măsură în mod vizual. Atelierul va avea în centru concepte din zona direcției artistice, creării și planificării de conținut pentru diferite rețele de socializare. La finalul întâlnirilor, vrem ca tinerii să aibă resursele necesare pentru a înțelege cum să folosească diferite platforme digitale creative pentru a transmite idei esențiale.",
    },
    {
      id: 6,
      img: eliseWilk,
      title:
        "Elise Wilk - Instrumente jurnalistice în documentarea textului de teatru",
      name: "Elise Wilk",
      descriere:
        "Cele mai multe piese de teatru contemporane au la bază o documentare riguroasă a subiectelor pe care le tratează, dramaturgul sau echipa de creație bazându-se în procesul de lucru pe tehnici specifice jurnalismului.  În cadrul workshopului, vom discuta despre propriile tehnici de documentare pentru un subiect (voi oferi exemple din practica proprie, dar și din practica altor artiști), apoi fiecare participant va alege o întâmplare din propria biografie pe care o va ficționaliza. ",
    },
    {
      id: 7,
      img: alexandraMoldovan,
      title: "Alexandra Moldovan - Să fim creativi ",
      name: "Alexandra Moldovan",
      descriere:
        "Atelierul de improvizație are scopul de a dezvolta creativitatea, de a îmbogăți imaginația, libertatea de exprimare, munca în echipă, conștientizarea prezentului și ajută la capacitatea de a ține un discurs în fața unui public cu ușurință și încredere în sine. Totodată, în cadrul acestui atelier, se vor învăța reguli care stau la baza improvizației, exerciții și jocuri specifice improvizației, jocuri de încălzire și inițiere. ",
    },
    {
      id: 8,
      img: Hunor,
      title: "Hunor Kontczey – Arte Media",
      name: "Kontczey Hunor",
      descriere:
        "Atelierul are ca țintă introducerea elevilor în domeniul digital de artă, incluzând captarea materialului și editarea lui, atenția fiind pe material videografic.Atelierul este structurat pe patru părți: Introducerea în videografie, practică de filmare, practică de editare, evaluarea scurt-metrajelor.",
    },
    {
      id: 9,
      img: andreiSuciu,
      title: "Andrei Suciu - Graphic Design în Artă ",
      name: "Suciu Andrei-Aurel",
      descriere:
        "Atelier educativ inovator, dedicat adolescenților, având scopul de a-i iniția în tainele designului grafic și de a stimula creativitatea lor prin intermediul artei și al tehnologiei.Acest atelier propune un cadru interactiv și captivant în care tinerii vor fi ghidați în procesul de creație al unui afiș și al unui logo.",
    },
  ];



  export const atelierDetalii2 = [
    {
      id:1,
      img: rares,
      title: 'Statut, relație și comunicare în spațiul mic',
      name: 'Rareș Budileanu',
      descriere: 'Atunci când te afli într-un spațiu mic, cu publicul la 1-2 metri distanță, toate coordonatele jocului se schimbă fațp de abordarea unui rol pe o scenă mare.',
      descriere1: 'Totul este mai filmic, mai prezent si mai ales trebuie să fie incărcat de adevăr. Actorul, în cazul nostru adolescentul, trebuie să știe că într-un spațiu de joc mic și ce mai (aparent) neînsemnat gest poate însemna o lume întreagă și se vede imediat.',
      descriere2: 'De aceea trebuie să ne cunoaștem pe noi înșine ca individ din toate puntele de vedere: limbaj, gest, mișcare, improvizație, comunicare, relație, pentru a reuși să transmitem publicului cu precizie ceea ce dorim.',
      descriere3: '',
    },
    {
      id:2,
      img: ancaLoghin,
      title: 'Text și subtext',
      name: 'Anca Loghin',
      descriere: 'Este un atelier care are ca obiectiv o mai bună înțelegere a textelor prin intermediul citirii lor din perspectiva artistică.',
      descriere1:'Cum analizăm personajele și situațiile din punct de vedere al spectacolului de teatru?',
      descriere2:'Ce se modifică atunci când trecem de la lectură la joc?',
      descriere3:'Cum apare emoția atunci când ne conturăm personajul?',
      descriere4:'Cum ajungem de la emoție la empatie?',
      descriere5:'Cum ne ajută empatia atât pe scenă cât și în viața reală?',
      descriere6:'Vom încerca să răspundem împreună la aceste întrebări citind, analizând și interpretând atât fragmente din texte clasice cât și din dramaturgia contemporană.',
    },
    {
      id:3,
      img: Szabi,
      title: 'Gestionarea diferitelor situații prin mijloace teatrale',
      name: 'Szabolcs Csiki',
      descriere: 'Prin acest atelier propun o apropiere de sine prin exerciții de conștientizare a corpului, a emoțiilor și procesul de vorbire.',
      descriere1: 'Orele vor fi structurate în două părți: prima parte va consta din exerciți care ajută expresia corporală conștientă, iar a doua parte se va axa mai ales pe exerciții de improvizație, pentru că în acest fel se poate concretiza cel mai eficient creativitatea fiecărui elev.',
      descriere2: 'la început elevii vor improviza non-verbal, iar într-un stadiu mai avansat vor improviza și prin mijloace verbale.'
    },
    {
      id:4,
      img: Luiza,
      title: 'Vocea - Producție, Proiecție, Protecție',
      name: 'Luiza Zan',
      descriere: 'Vocea este corpul gândirilor noastre, iar perioade tensionate, cu angoasă, stres. nerăbdare, nesiguranță, însingurare, ne fac corpul gândurilor să reflecte aceste stări.',
      descriere1: 'De aici pornesc manifestări clinice, precum disfonii, laringite sau alte forme de afecțiuni manifestate la nivelul aparatului vocal.',
      descriere2: 'Acest workshop are ca scop prezentarea diferitelor tehnici vocale - tehnica vocii vorbite, vocea cântată, vocea teatrală, tehnici de relaxare vocală (vocal yoga), precum și a tehnicilor de respirație corespunzătoare.',
    },
    {
      id:5,
      img: Blanka,
      title: 'Statut, relație și comunicare în spațiul mic',
      name: 'Blanca Macaveiu',
      descriere: 'Spontaneitate, exprimare individuală și inovație',
      desscriere1: 'Procesul creativ prin dans și mișcare le permite tinerilor să creeze relații cu ceilalți în cadrul unui câmp operativ care este grupul, alcătuit de persoane ce interacționează, se integrează și relaționează unele cu celelalte.',
      descriere2: 'Dansul este un spațiu unde putem avea grijă de noi, unde potențialul creativ al fiecăruia este stimlat prin comunicarea expresiv-relațională a corpului.',
      descriere3: 'Acest spațiu este edparte de obiectivele tehnice și estetice tipice dansului de performanță sau dansului prefosionist sau dansului sportiv; are loc într-un mediu sigur, bine definit și lipsit de judecată.',
      descriere4: '',
    },
    {
      id:6,
      img: irina,
      title: 'Dezvoltare personală pentru adolescenți',
      name: 'Irina Săcuiu',
      descriere: 'Adolescenții trec prin stări psihio-emoționale și transformări fizice atât de diverse, încât cu greu își înteleg trăirile și reacțiile.',
      desscriere1: 'Nevoia de a aparține unui grup de vârsta lor devine principaul interes, familia și școala trecând pe locuri secunde.',
      descriere2: 'Sistemul endocrin(hormonii) se recalibrează ceea ce conduce la emoții ample, la dificultăți de exprimare a trăirilor itnerioare și, implicit, la o comunicare deficitară cu adulții din jur.',
      descriere3: 'Prin grupurile de dezvoltare personală îi îndrum pe adolescenți să-și înțeleagă, să-și identifice și să-și accepte stările emoționale ca fiind normale perioadei de viață în care se află.',
      descriere4: '',
    },
    {
      id:7,
      img: Ligia,
      title: 'Prezenți în Digital',
      name: 'Ligia Ungur',
      descriere: 'Scopul acestui atelier este să le ofere adolescenților o privire critică asupra mediului online și să le ofere învățarea practică a ce presupune construirea unei comunități și interacțiunea cu aceasta pe rețelele sociale, cum să promoveze activitatea/serviciile unui brand prin mesaje consistente, care reușesc să atragă și să crească o comunitate viabilă.',
      desscriere1: 'La finalul atelierelor, tinerii vor deprinde și își vor dezvolta abilitățile creative, strategice și de promovare, abilități necesare într-o lume digitalizată.',
      descriere2: '',
      descriere3: '',
      descriere4: '',
    },
  ]