import React, { useState, useEffect } from "react";

import bikeathon from '../../utils/images/Bikeathon.jpg';
import BikeVideo from '../../utils/videos/BikeVideo.mp4';
import ReactPlayer from 'react-player';

const Bikeathon = () => {

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1200);
    };

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    return (
        <div 
            style={{
                // marginTop: '4rem',
                marginBottom: '10rem',
                backgroundColor: '#73756f',
                paddingBottom: '3rem',
                paddingTop: '3rem'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: isWideScreen ? 'row' : 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80%',
                    margin: 'auto'
                }}
            >
                <div
                    style={{
                    flexBasis: isWideScreen ? '40%' : '70%',
                    maxWidth: isWideScreen ? '40%' : '70%',
                    textAlign: 'center',
                    margin: '10px',
                    }}
                >
                    <img src={bikeathon} alt="bikeathon" width={500} height={500} />
                </div>
                <div
                    style={{
                    flexBasis: isWideScreen ? '60%' : '70%',
                    maxWidth: isWideScreen ? '60%' : '70%',
                    textAlign: 'center',
                    margin: '10px',
                    }}
                >
                    <h3>Pedalez EXPLOZIV</h3>
                    <h3 style={{ color: '#013220'}}>Perioada de donații pentru proiectul ExplozivART s-a prelungit cu 2 săptămâni(25 Septembrie). Vă mulțumim pentru sprijin și interes!</h3>
                    <h3>
                        INCUBATOR13 lansează o provocare - să adunăm bani pt proiectul ExplozivART ediția 2024 în cadrul evenimentului Bikeathon.
                    </h3>
                    <h3>
                        Donează sau înscrie-te la pedalat și devii ambasadorul nostru. Răspândește vestea între prieteni și haideți să avem 2 zile - 14/15 septembrie pline de mișcare, voie bună și altruism.
                    </h3>
                    <h3>
                        Poți accesa și site-ul <a href="https://bikeathon.ms">bikeathon.ms</a>
                    </h3>
                    <h3>Fiți alături de noi pentru a avea o ediție ExplozivART deosebită.</h3>
                    <h3>Pentru a descărca aplicațiile mobile accesați ghidul de <a href="https://www.instagram.com/p/C_uxGyzONBI/?igsh=MWNmdW5wcXplMHNndg==">Facebook</a> sau  <a href="https://www.facebook.com/share/p/YvZevckMbV88qNM4/?mibextid=WC7FNe">Instagram</a></h3>
                    <h3>Susține-ne aici:<a href="https://bikeathon.ms/projects/42">https://bikeathon.ms/projects/42</a></h3>
                </div>
                
                </div>
        </div>
    )
}

export default Bikeathon;